import {Amplify} from "aws-amplify";
import {Hub} from "aws-amplify/utils";
import {signOut} from 'aws-amplify/auth';
import {DataStore, syncExpression} from "@aws-amplify/datastore";
import {
    Forms, Submissions, Users, Accounts, Tokens, Projects, Counters,
} from "./models";
import "./App.css";

import {useEffect, useState, useRef} from "react";
import {useFormDispatch, useForm} from "./reducers";
import {AlertProvider} from "./alertContext";

import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Main from "./components/main.js";
import Navbar from "./components/navbar";

import {
    Authenticator, CheckboxField, useAuthenticator,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";

import amplifyconfig from "./amplifyconfiguration.json";

import {Stack} from "@mui/material";

import {fetchUserAttributes} from "aws-amplify/auth";

import {I18n as i18n} from "aws-amplify/utils";

import {translations} from "@aws-amplify/ui-react"; // authenticator translations
import uiDictionary from "./assets/uiDictionary";
import authDictionary from "./assets/authDictionary";

import Events from "./components/events";
import AutoSignIn from "./components/autoSignIn";

Amplify.configure(amplifyconfig);

/*
Detect if signin or refresh, using useRef

1. On signIn
    start datastore
    get form token from url.split('/')[1]; save it to localstorage
    if(token)
        sync using token

2. Re-render when signed in
    get form token from url or localstorage
    if(!token)
        sync Users
        get token from user data
        ask to resume work on form
    if(token)
       sync Forms and Users

Sign out: stop and clear datastore, clear localstorage
 */

const App = () => {
        // console.log("app.js");
        const [isDataStoreReady, setIsDataStoreReady] = useState(false);
        const dispatch = useFormDispatch();
        const {form, submissions, language, pageNumber, user} = useForm();
        const [showSignOutMessage, setShowSignOutMessage] = useState(false);
        const [addUserToProject, setAddUserToProject] = useState(false);
        const returnUrlRef = useRef(null);

        let storedToken = localStorage.getItem("HLtoken");
        const urlToken = window.location.pathname.split("/")[1];
        let token = urlToken || storedToken; //assume one or the other

        if (urlToken && !storedToken) { //initial loading - no storedToken, but url token
            localStorage.setItem("HLtoken", urlToken);
            storedToken = urlToken;
        }

        if (!urlToken && storedToken) { // initial loading - no url token but storedToken; reload the page
            window.location.href = window.location.origin + "/" + storedToken;
        }

        if (urlToken !== storedToken) { //subsequent load - storedToken exists
            console.log("token changed");
            localStorage.setItem("HLtoken", urlToken);
            returnUrlRef.current = window.location.href;
            setShowSignOutMessage(false);
            signOut();
        }

        const browserLanguage = navigator.language.split('-')[0];
        const [formLanguage, setFormLanguage] = useState(browserLanguage || "en");

// workflow variables
        const [authUser, setAuthUser] = useState(null);

// set up dictionaries

        function mergeDictionaries(dicts) {
            const result = {};
            dicts.forEach(dict => {
                Object.keys(dict).forEach(lang => {
                    if (!result[lang]) {
                        result[lang] = {};
                    }
                    result[lang] = {...result[lang], ...dict[lang]};
                });
            });

            return result;
        }

        useEffect(() => {
            if (form.dictionaries) {
                const formDictionary = JSON.parse(form.dictionaries.find(dictionary => dictionary.type === "formDictionary").phrases);
                i18n.putVocabularies(mergeDictionaries([authDictionary, translations, uiDictionary, formDictionary]));
            }
            if (form.meta.returnUrl) {
                returnUrlRef.current = form.meta.returnUrl;
            }
        }, [form]);

// workflow hooks...

        useEffect(() => {
            // get authUser on refresh
            const fetchUser = async () => {
                console.log('fetch user');
                try {
                    const authUserAttributes = await fetchUserAttributes();
                    setAuthUser(authUserAttributes);
                    dispatch({
                        type: "SET_LANGUAGE", value: browserLanguage || authUserAttributes["custom:language"],
                    });
                } catch (error) {
                    // console.log(error)
                    setAuthUser(null);
                }
            };

            // Listen for sign-in and sign-out events
            const listener = Hub.listen("auth", async (data) => {
                const {payload} = data;
                // console.log(payload)
                switch (payload.event) {
                    case "signedIn":
                        setShowSignOutMessage(false);
                        fetchUser();
                        break;
                    case "signedOut": {
                        const cleanup = async () => {
                            console.log('cleanup')
                            setShowSignOutMessage(true);
                            setAuthUser(null);
                            try {
                                await DataStore.stop();
                                await DataStore.clear();
                                console.log('datastore cleared')
                            } catch (e) {
                                console.log(e)
                            }
                            localStorage.clear();
                            //TODO hack for IAS only
                            window.location.href = returnUrlRef.current || window.location.origin;
                        };
                        cleanup();
                        break;
                    }
                    default:
                        console.log(payload.event, payload.data);
                        break;
                }
            });

            fetchUser();

            return () => {
                listener(); // This will remove the listener
            };
        }, [browserLanguage, dispatch]);

        useEffect(() => {
            console.log(token, localStorage.getItem("HLtoken"));
            if (token) {
                localStorage.setItem("HLtoken", token);
            } else {
                if (localStorage.getItem("HLtoken")) {
                    window.location.href = window.location.origin + "/" + localStorage.getItem("HLtoken");
                }
            }
        }, [token]);

        useEffect(() => {
            console.log("add user to project", addUserToProject, form?.projectToken);
            if (!(addUserToProject && form?.projectToken)) {
                return
            }
            const url =
                "https://na2nfrohc26m5gwlhkoii74e4e0oufrh.lambda-url.eu-west-1.on.aws/ ";

            const data = {
                user: {familyName: user.familyName, givenName: user.givenName, email: user.email, id: user.id},
                token: token,
                projectToken: form?.projectToken,
            };

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((data) => {
                    setAddUserToProject(false)
                    console.log("Success:", data);
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }, [addUserToProject, form, token, user]);

        const changeUserToken = async (user) => {
            // Create a new currentState object with the updated token
            const updatedState = {
                ...user.currentState, // spread operator to copy existing properties
                token, // update the token property
            };

            try {
                // Save the updated user object back to DataStore
                await DataStore.save(Users.copyOf(user, (updatedUser) => {
                    updatedUser.currentState = updatedState;
                }));
                // add the user to the project
                setAddUserToProject(true)
            } catch (e) {
                console.error("Error updating user token:", e);
            }
        };

        useEffect(() => {
            if (authUser) {
                if (token) {
                    console.log("sync all", token);
                    DataStore.configure({
                        syncExpressions: [
                            syncExpression(Forms, () => {
                                return (form) => form.token.eq(token);
                            }), syncExpression(Submissions, () => {
                                return (submissions) => submissions.userId.eq(authUser.sub) && submissions.formToken.eq(token);
                            }), syncExpression(Users, () => {
                                return (users) => users.id.eq(authUser.sub);
                            }), syncExpression(Accounts, () => {
                                return (accounts) => accounts.id.eq(0); // A condition that always evaluates to false
                            }), syncExpression(Tokens, () => {
                                return (tokens) => tokens.id.eq(0); // A condition that always evaluates to false
                            }), syncExpression(Projects, () => {
                                return (projects) => projects.id.eq(0); // A condition that always evaluates to false
                            }), syncExpression(Counters, () => {
                                return (counters) => counters.id.ne(0);
                            }),],
                    });
                } else {
                    console.log("sync users", authUser);
                    DataStore.configure({
                        syncExpressions: [
                            syncExpression(Forms, () => {
                                return (form) => form.token.eq(0);
                            }), syncExpression(Submissions, () => {
                                // return (submissions) => submissions.and((submissions) => [submissions.userId.eq(null), submissions.formToken.eq(null),]);
                                return (submissions) => submissions.userId.eq(0) && submissions.formToken.eq(0);
                            }), syncExpression(Users, () => {
                                return (users) => users.id.eq(authUser.sub);
                            }), syncExpression(Accounts, () => {
                                return (accounts) => accounts.id.eq(0); // A condition that always evaluates to false
                            }), syncExpression(Tokens, () => {
                                return (tokens) => tokens.id.eq(0); // A condition that always evaluates to false
                            }), syncExpression(Projects, () => {
                                return (projects) => projects.id.eq(0); // A condition that always evaluates to false
                            }), syncExpression(Counters, () => {
                                return (counters) => counters.id.eq(0); // A condition that always evaluates to false
                            }),],
                    });
                }

                // don't start the datastore until logged in
                const startDataStore = async () => {
                    await DataStore.start();
                    console.log("Datastore started");
                    setIsDataStoreReady(true);
                };

                startDataStore();

                // re-render after synchronisation
                Hub.listen("datastore", async (hubData) => {
                    const {event} = hubData.payload;
                    console.log(hubData.payload)
                    if (event === "ready") {
                        DataStore.observeQuery(Forms, (f) => f.token.eq(token), {}).subscribe((snapshot) => {
                            const {items, isSynced} = snapshot;
                            if (items.length && isSynced) {
                                console.log('form synced')
                                dispatch({type: "CHANGE_FORM", value: items[0]});
                            }
                        });

                        DataStore.observeQuery(Submissions, (f) => f.and((f) => [f.userId.eq(authUser.sub), f.formToken.eq(token)]), {}).subscribe((snapshot) => {
                            const {items, isSynced} = snapshot;
                            if (items.length && isSynced) {
                                // console.log('submissions synced')
                                dispatch({type: "LOAD_SUBMISSIONS", value: items});
                            }
                        });

                        DataStore.observeQuery(Users, (u) => u.id.eq(authUser.sub), {}).subscribe((snapshot) => {
                            const {items, isSynced} = snapshot;
                            if (items.length && isSynced) {
                                const user = items[0];
                                // console.log('setting user', user)
                                dispatch({type: "SET_USER", value: user});
                                if (user?.currentState?.token !== token) {
                                    changeUserToken(user);
                                }
                                if (!token) {
                                    try {
                                        window.location.href = window.location.origin + "/" + user.currentState.token;
                                    } catch (e) {
                                        console.log(e);
                                    }
                                }
                            }
                        });
                    }
                });
            }
        }, [authUser, token]); // eslint-disable-line

//TODO handle form mutations
        useEffect(() => {
            return () => {
                // console.log('form changed:', form)
            };
        }, [form]);

        useEffect(() => {
            // console.log(language)
            setFormLanguage(language)
            i18n.setLanguage(language);
        }, [language])

        useEffect(() => {
            return () => {
                // console.log('form changed:', form)
            };
        }, [form]);

//TODO handle submission mutations
        useEffect(() => {
            return () => {
                // console.log('submissions changed:', submissions)
            };
        }, [submissions]);

        const hasNav = !!form.pages[pageNumber].navigation?.length;

        return (
            <div>
                {showSignOutMessage && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        background: 'rgba(0,0,0,1)',
                        zIndex: 1000,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}>
                        <h1 style={{color: 'white', fontSize: '4rem'}}>Signing you out...</h1>
                    </div>
                )}
                <AlertProvider>
                    <AutoSignIn>
                        <Authenticator
                            components={{
                                SignUp: {
                                    FormFields() {
                                        const {validationErrors} = useAuthenticator();

                                        return (<>
                                            <Authenticator.SignUp.FormFields/>
                                            {/* <SelectField
                    name="custom:language"
                    label={i18n.get("Language")}
                    defaultValue={selectedLanguage}
                    onChange={handleLanguageChange}
                  >
                    {languageOptions.map((lang) => (
                      <option key={lang.value} value={lang.value}>
                        {lang.label}
                      </option>
                    ))}
                  </SelectField>*/}

                                            <CheckboxField
                                                errorMessage={validationErrors.acknowledgement}
                                                hasError={!!validationErrors.acknowledgement}
                                                name="acknowledgement"
                                                value="yes"
                                                label={<span
                                                    dangerouslySetInnerHTML={{__html: i18n.get("Terms")}}
                                                />}
                                            />
                                        </>);
                                    },
                                },
                            }}
                            services={{
                                async validateCustomSignUp(formData) {
                                    if (!formData.acknowledgement) {
                                        return {
                                            acknowledgement: i18n.get("AgreeTerms"),
                                        };
                                    }
                                },
                            }}
                        >
                            {({signOut}) => (<Stack
                                sx={{
                                    display: "flex", flexDirection: "column", height: "100vh",
                                }}
                            >
                                <Header logout={signOut}/>
                                {isDataStoreReady ? <Main language={formLanguage}/> : <div>Loading...</div>}
                                {hasNav && <Navbar/>}
                                <Footer/>
                                <Events/>
                            </Stack>)}
                        </Authenticator>
                    </AutoSignIn>
                </AlertProvider>
            </div>);
    }
;

export default App;

