import get from 'lodash.get';
import React from 'react';
import {getUrl} from "aws-amplify/storage";
import parse from "html-react-parser";
import excelIcon from "../assets/fileIcons/ms-excel.svg";
import csvIcon from "../assets/fileIcons/csv.svg";
import wordIcon from "../assets/fileIcons/ms-word.svg";
import pdfIcon from "../assets/fileIcons/pdf.svg";
import imageIcon from "../assets/fileIcons/image.svg";
import documentIcon from "../assets/fileIcons/document.svg";
import {Button} from "@mui/material";
import {I18n as i18n} from "aws-amplify/utils";
import {useForm} from "../reducers";
import {StorageImage} from '@aws-amplify/ui-react-storage';
import {countryCodes} from "../assets/countryCodes";

export default function Summary(props) {
    const attributes = JSON.parse(props.control.attributes);

    const getIconForButton = (icon) => {
        const iconMap = {
            'xlsx': excelIcon,
            'xls': excelIcon,
            'csv': csvIcon,
            'doc': wordIcon,
            'docx': wordIcon,
            'pdf': pdfIcon,
            'png': imageIcon,
            'jpg': imageIcon,
            'jpeg': imageIcon,
            'gif': imageIcon,
            // Add more file extensions as needed
        };

        return iconMap[icon] || documentIcon;
    };
    const {form, submissions, submissionId, language} = useForm();
    const submission = submissions.find((item) => item.id === submissionId);
    const countries = countryCodes['en-gb'] || form.meta?.countryCodes['en-gb'];

    const downloadFile = async (fileKey, fileName) => {
        try {
            const signedUrl = await getUrl({key: fileKey});
            const response = await fetch(signedUrl.url.href);
            const blob = await response.blob();
            const downloadUrl = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = downloadUrl;
            link.setAttribute("download", fileName); // Set the file name.
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (err) {
            console.error("Error downloading file:", err);
        }
    };

    // Get the files
    function getFileValue(key) {
        let dataKey;
        let files;

        // If the key contains square brackets, it's indicating a nested path
        if (key.includes('[')) {
            // Extract the actual dataKey from the string with square brackets
            dataKey = key.match(/\['(.*?)'\]/)[1]; // Extract 'file_1' from the key "submission.files['file_1']"
            files = submission?.data?.submission?.files || [];

        } else {
            // If no square brackets, it's a top-level key
            dataKey = key;  // Direct dataKey like 'file_1'
            files = submission?.files;
        }

        const file = files.find(f => f.dataKey === dataKey);
        return file ? file : undefined;
    }

    return (
        <>
            {attributes.fields.map((f, i) => {
                const {key, label, type} = f;
                let value = get(submission?.data || {}, key, null);
                let fragment;

                switch (type) {
                    case "string":
                        value = typeof value === "string"? value : null;
                        fragment = <><strong>{label}:</strong><br/>{parse(value || '')}</>
                        break;
                    case "boolean":
                        value = typeof value === "boolean"? value : null;
                        fragment = <><strong>{label}:</strong><br/>{value ? 'Yes' : 'No'}</>
                        break;
                    case "country":
                        const country = countries.find((item) => item.code === value).country;
                        fragment = <><strong>{label}:</strong><br/>{parse(country || '')}</>
                        break;
                    case "authors":
                        if (value) {
                            fragment = value.map((author, index) => (
                                <React.Fragment key={index}>
                                    <span style={{textDecoration: author.presenting ? 'underline' : 'none'}}>
                                      {parse(
                                          `${author.givenName} ${author.familyName}<sup>${author.affiliations
                                              .filter((n) => n !== 0)
                                              .map((n) => n)
                                              .join(', ')}</sup>`
                                      )}
                                    </span>
                                    {index < value.length - 1 && ', '}
                                </React.Fragment>
                            ))
                        }
                        fragment = <><strong>{label}:</strong><br/>{fragment}</>
                        break;
                    case "affiliations":

                        if (value) {
                            fragment = value.map((affiliation, index) => (
                                <React.Fragment key={index}>
                                    {value.length > 1 && <sup>{index + 1}</sup>}
                                    {affiliation.institution}, {affiliation.country}
                                    {index < value.length - 1 && ', '}
                                </React.Fragment>
                            ))
                        }
                        fragment = <><strong>{label}:</strong><br/>{fragment}</>
                        break;
                    case "file":
                        const item = getFileValue(key);
                        if (item) {
                            const extension = item.fileKey.split('.').pop();
                            const fileName = `${item.dataKey}.${extension}`;
                            const isImage = ['jpg', 'jpeg', 'png', 'gif'].includes(extension);
                            value = true;
                            fragment = <a href="#" onClick={(e) => {
                                e.preventDefault();
                                downloadFile(item.fileKey, fileName);
                            }}>{label}</a>

                            fragment = <div style={{

                                justifyContent: 'flex-start',
                                flexDirection: 'column',
                                marginTop: '10px'
                            }}>
                                {isImage && (
                                    <>
                                        <StorageImage
                                            alt="Preview"
                                            path={`public/${item.fileKey}`}
                                            style={{
                                                width: '150px',
                                                marginBottom: '10px'
                                            }}
                                            onClick={() => downloadFile(item.fileKey, fileName)}
                                        />
                                        <br/>
                                    </>
                                )}

                                <Button
                                    variant="contained"
                                    onClick={() => downloadFile(item.fileKey, fileName)}
                                    style={{
                                        alignItems: 'center',
                                        width: 'auto'
                                    }} // Ensures text and icon are aligned
                                >
                                    {i18n.get(label)}
                                    <img
                                        src={getIconForButton(extension)}
                                        alt="Button Icon"
                                        style={{
                                            marginLeft: '10px',
                                            height: '20px',
                                            filter: 'invert(1)'
                                        }} // Makes the icon white
                                    />
                                </Button>
                            </div>

                        }
                        break;
                    default:
                }
                return value !== null && value !== undefined && value !== '' && (
                    <p key={i}>
                        {fragment}
                    </p>
                );
            })}
        </>
    );
}
