import React, {useState, useEffect, useMemo} from "react";
import {useForm, useFormDispatch} from "../reducers";
import {useAlert} from "../alertContext";
import parse from "html-react-parser";
import {I18n as i18n} from "aws-amplify/utils";
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import StarterKit from "@tiptap/starter-kit";
import {
    MenuButtonRedo,
    MenuButtonUndo,
    MenuButtonBold,
    MenuButtonItalic,
    MenuButtonUnderline,
    MenuButtonBulletedList,
    MenuButtonOrderedList,
    MenuButtonSubscript,
    MenuButtonSuperscript,
    MenuControlsContainer,
    MenuDivider,
    // MenuSelectHeading,
    RichTextEditor,
} from "mui-tiptap";
import Underline from '@tiptap/extension-underline';
import Superscript from '@tiptap/extension-superscript';
import Subscript from '@tiptap/extension-subscript';
import {
    Select,
    Radio,
    RadioGroup,
    TextareaAutosize,
    TextField,
    useTheme,
    MenuItem,
    FormControl,
    FormControlLabel,
    FormLabel,
    Checkbox,
    FormGroup,
    Box,
    Card,
    CardContent,
    Fab,
    Zoom,
    Badge
} from "@mui/material";

import {
    countryCodes
} from "../assets/countryCodes";
import {DataStore} from "@aws-amplify/datastore";
import {Submissions} from "../models";
import SubmissionList from "../controls/submissionList";
import Summary from "../controls/summary";
import Authors from "../controls/authors";
import FileUpload from "../controls/fileUpload";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VerifiedIcon from "@mui/icons-material/Verified";
import AsteriskIcon from "../assets/asterisk"
import {DateCalendar} from "@mui/x-date-pickers";
import Maths from "../controls/maths";
import ActionButton from "../controls/actionButton";

export default function Main() {
    //TODO - obtain user language
    const UIlanguage = "en-gb";
    const theme = useTheme();
    const dispatch = useFormDispatch();

    const {form, submissions, submissionId, pageNumber, language} = useForm();
    const page = form.pages[pageNumber];
    let submission = submissions.find((item) => item.id === submissionId);

    const {triggerAlert} = useAlert();

    const controls = useMemo(() => {
        return page.controls || [];
    }, [page.controls]);

    /*
      -----------------------------------
      Scroll position
      */

    // handle scrolling back to top...
    function throttle(func, delay) {
        let lastCall = 0;
        return function (...args) {
            const now = new Date().getTime();
            if (now - lastCall > delay) {
                lastCall = now;
                func(...args);
            }
        };
    }

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = useMemo(() => throttle(() => {
        const position = document.getElementById("main-content").scrollTop;
        setScrollPosition((currentPosition) => currentPosition !== position ? position : currentPosition);
    }, 100), [setScrollPosition]);

    useEffect(() => {
        const contentContainer = document.getElementById("main-content");
        if (contentContainer) {
            contentContainer.addEventListener("scroll", handleScroll);

            // Cleanup function to remove the event listener
            return () => contentContainer.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]); // handleScroll is stable thanks to useCallback, so it won't cause the effect to rerun unnecessarily

    const handleScrollTopClick = (behavior) => {
        const anchor = document.getElementById("back-to-top-anchor");
        if (anchor) {
            // Conditionally set the behavior option based on the provided argument
            const scrollOptions = {block: "start"};
            if (behavior === "smooth") {
                scrollOptions.behavior = "smooth";
            }
            anchor.scrollIntoView(scrollOptions);
        }
    };

    // detect page change
    useEffect(() => {
        handleScrollTopClick("instant");
    }, [pageNumber]);

    /*
      -----------------------------------
      Translations
      */

    useEffect(() => {
        i18n.setLanguage(language || 'en');
    }, [language])

    useEffect(() => {
        if (submission) {
            if (submission && submission.meta.submissionLanguage !== language) {
                DataStore.save(Submissions.copyOf(submission, (updatedSubmission) => {
                    updatedSubmission.meta.submissionLanguage = language;
                }));
            }
        }
    }, [submission, language]);

    /*
      -----------------------------------
      Lists
      */
    const countryList = useMemo(() => {
        const list = countryCodes[UIlanguage]?.sort((a, b) => a.Country > b.Country ? 1 : -1) || [];
        return list.map((language) => ({
            name: language.Country, value: language.Country,
        }));
    }, [UIlanguage]);

    /*
      ----------------------------------------------------------------------
      Values and validation
      */

    // Initial state
    const [controlStates, setControlStates] = useState({});

    useEffect(() => {
        if (controlStates) {
            const numberOfInvalidControls = controls.reduce((count, control) => {
                const state = controlStates[control.key];
                if (state && !state.isValid && !state.isHidden) {
                    count++;
                }

                return count;
            }, 0);

            dispatch({
                type: "SET_NO_OF_INVALID_CONTROLS", value: numberOfInvalidControls,
            });

            if (numberOfInvalidControls) {
                triggerAlert({
                    message: `${numberOfInvalidControls} required questions not answered`,
                    severity: "error",
                    vertical: "bottom",
                    horizontal: "center",
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [controlStates, pageNumber]);

    function calculatePercentComplete(submission, form) {
        let requiredCount = 0;
        let totalInvalidControls = 0;

        form.pages.forEach(page => {
            // Calculate required count excluding hidden controls
            requiredCount += page.controls.filter(control => control.required && !controlStates[control.key]?.isHidden).length;

            // Iterate over each control to count invalid entries, excluding hidden controls
            page.controls.forEach(control => {
                if (control.required && !controlStates[control.key]?.isHidden) {
                    // Check if there is a valid entry in the submission data
                    let value = submission?.data[control.key];
                    if (value === null || value === undefined || value === "") {
                        totalInvalidControls++;
                    }
                }
            });
        });

        // Calculate and return the percentage of completed controls
        if (requiredCount === 0) {
            return 100; // Return 100% if there are no required controls
        } else {
            return Math.round((requiredCount - totalInvalidControls) / requiredCount * 100);
        }
    }


    // Initialize control states with form values from submission
    async function handleSubmissionChange(id) {
        let initialStates = {};
        const submission = submissions.find(submission => submission.id === id);

        const data = submission?.data || {}
        form.pages.forEach((page) => {
            page.controls.forEach(control => {

                // Use existing submission data if available, else default to an empty string or other default value
                const existingValue = data[control.key] ?? "";
                let maxWords = null;
                try {
                    maxWords = JSON.parse(control.attributes).maxWords
                } catch (e) {
                    //
                }
                if (control.key) {
                    let words = [];
                    if (typeof existingValue === "string") {
                        words = existingValue.match(/\S+/g) || []; // Match non-space characters to count words
                    }
                    const wordCount = words.length;
                    // const attributes = JSON.parse(control?.attributes)

                    // Create the state object for each control
                    initialStates[control.key] = {
                        value: existingValue,
                        isValid: isValidValue(existingValue, !!control.required),
                        isHidden: false,
                        required: !!control.required,
                        count: wordCount,
                        maxWords: maxWords,
                        hover: -1
                    }
                }
            })
        });

        form.pages.forEach(page => {
            page.controls.forEach(control => {
                const attributes = JSON.parse(control?.attributes || "{}")

                if (attributes?.actions) {
                    attributes.actions.forEach(action => {

                        switch (action.operation) {
                            case "show":
                                switch (action.predicate) {
                                    case "eq":
                                        if (initialStates[control.key].value === action.value) {
                                            action.fields.forEach(field => {
                                                initialStates[field].isHidden = false;
                                            })
                                        }
                                        break;
                                    case "ne":
                                        if (initialStates[control.key].value !== action.value) {
                                            action.fields.forEach(field => {
                                                initialStates[field].isHidden = false;
                                            })
                                        }
                                        break;
                                    default:
                                }
                                break;
                            case "hide":
                                switch (action.predicate) {
                                    case "eq":
                                        if (initialStates[control.key].value === action.value) {
                                            action.fields.forEach(field => {
                                                initialStates[field].isHidden = true;
                                            })
                                        }
                                        break;
                                    case "ne":
                                        if (initialStates[control.key].value !== action.value) {
                                            action.fields.forEach(field => {
                                                initialStates[field].isHidden = true;
                                            })
                                        }
                                        break;
                                    default:
                                }
                                break;
                            case "block":

                                switch (action.predicate) {
                                    case "eq":
                                        // block further progress in submission
                                        dispatch({
                                            type: 'SET_BLOCKED',
                                            value: (initialStates[control.key] && initialStates[control.key].value) === action.value

                                        });
                                        break;
                                    case "ne":
                                       dispatch({
                                            type: 'SET_BLOCKED',
                                            value: (initialStates[control.key].value || null) !== action.value
                                        });
                                        break;
                                    default:
                                }
                                break;

                            /*  case "unblock":
                                  console.log('unblocking', !!initialStates[control.key].value , action.value)
                                  switch (action.predicate) {
                                      case "eq":
                                          // block further progress in submission
                                          dispatch({
                                              type: 'SET_BLOCKED',
                                              value: (initialStates[control.key].value || null) !== action.value
                                          });
                                          break;
                                      case "ne":
                                          dispatch({
                                              type: 'SET_BLOCKED',
                                              value: (initialStates[control.key] && initialStates[control.key].value) === action.value

                                          });
                                          break;
                                      default:
                                  }
                                  break;*/
                            default:
                        }
                    })
                }
            })
        })
        setControlStates(initialStates);
    }

    useEffect(() => {
        // Assuming submission can change over time, initialize controlStates here
        if (submissionId) {
            handleSubmissionChange(submissionId);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [submissionId]);

    function isValidValue(value, required, attributes) {
        // Check if the value is not undefined or null
        const hasValue = typeof value === 'boolean' ? true : value !== undefined && value !== null && value !== "";

        if (attributes?.validity) {
            // if there's a validity requirement, return true if required is falsey or if value is et
            switch (attributes.validity?.predicate) {
                case "eq":
                    return !required || value === attributes.validity?.value
                default:
            }
        } else {
            // return true if required is falsey; otherwise, check if a value is actually present
            return !required || hasValue;
        }
    }

    useEffect(() => {
        //create a copy of the data object
        const dataCopy = Object.keys(controlStates).reduce((acc, key) => {
            acc[key] = controlStates[key].value;
            return acc;
        }, {});

        if (submission) {
            DataStore.save(Submissions.copyOf(submission, (updatedSubmission) => {
                updatedSubmission.data = dataCopy;
                updatedSubmission.meta.page = pageNumber;
                updatedSubmission.meta.percentComplete = calculatePercentComplete(updatedSubmission, form);
            }))
                .then((r) => {
                    submission = r;
                })
                .catch((error) => {
                    triggerAlert({
                        message: `Update failed - ${error.message}`, severity: "error",
                    });
                    console.error(`Failed to update: ${error.message}`);
                });
        }
    }, [controlStates]);

    async function handleHoverChange(key, value) {
        setControlStates(prevStates => {
            let newState = {...prevStates};
            newState[key] = {
                ...prevStates[key],
                hover: value
            }
            return newState;
        })
    }

    async function handleInputChange(key, value, save) {
        console.log('handleInputChange', key, value)
        // let saveData = save ?? true;
        //TODO - state history, including timestamp

        // check word count
        let count = 0;

        if (typeof value === "string") {
            const limit = controlStates[key].maxWords ?? 0;
            if (limit) {
                let inWord = false; // Track whether we're currently inside a word
                let cutOffIndex = value.length; // Default to the full length of the text

                for (let i = 0; i < value.length; i++) {
                    if (/\S/.test(value[i])) { // Check if the character is not a space
                        if (!inWord) { // We're entering a new word
                            count++; // Increment word count
                            inWord = true; // We are now inside a word
                            if (count > limit) { // If the limit is reached, set cut off point to current position
                                cutOffIndex = i;
                                break; // Exit the loop as we've reached the word limit
                            }
                        }
                    } else {
                        inWord = false; // We've hit a space, so we're no longer in a word
                    }
                }
                // Truncate the text at the last valid position that does not exceed the word limit
                value = value.substring(0, cutOffIndex);
            }
        }

        setControlStates(prevStates => {
            console.log('updating controlStates with ' + key + ':' , value)

            let newState = {...prevStates};

            // Check if 'key' is an object (multiple updates) or a single string (single update)
            /* const isMultipleUpdates = typeof key === 'object' && key !== null;

             if (isMultipleUpdates) {
                 // Handle multiple updates
                 Object.entries(key).forEach(([updateKey, updateValue]) => {
                     const {value, count} = updateValue;
                     newState[updateKey] = {
                         ...prevStates[updateKey],
                         value: value,
                         isValid: isValidValue(value, !!prevStates[updateKey]?.required),
                         count: count,
                     };
                 });
             } else {
                 // Handle single update
                 newState[key] = {
                     ...prevStates[key], value: value, isValid: isValidValue(value, !!prevStates[key]?.required), // Your validation logic
                     count: count,
                 };
             }*/

            // check for show/hide and other actions
            const control = controls.find(control => control.key === key);
            const attributes = JSON.parse(control?.attributes || "{}")

            if (attributes?.actions) {
                attributes.actions.forEach(action => {
                    const setFieldVisibility = (isHidden) => {
                        action.fields.forEach(field => {
                            newState[field] = {
                                ...prevStates[field],
                                isHidden: isHidden
                            };
                        });
                    };

                    if (action.operation === "show") {
                        // newState[key] = {
                        //     ...prevStates[action.key],
                        //     isHidden: false
                        // }
                    } else if (action.operation === "hide") {
                        const isEqual = value === action.value;
                        const shouldHide = (action.predicate === "eq" && isEqual) || (action.predicate === "ne" && !isEqual);
                        setFieldVisibility(shouldHide);
                    }
                    if (action.operation === "block") {
                        const isEqual = value === action.value;
                        dispatch({
                            type: 'SET_BLOCKED',
                            value: action.predicate === "eq" && isEqual
                        });
                    }
                })
            }

            newState[key] = {
                ...prevStates[key],
                hover: value,
                value: value,
                isValid: isValidValue(value, !!prevStates[key]?.required), // Your validation logic
                count: count,
            };
            return newState;
        });

        //TODO - handle authors and affiliations
        /*
                // Determine if 'key' is an object representing multiple updates
                const isMultipleUpdates = typeof key === 'object' && key !== null;

                const updatedData = {...submission.data};

                if (isMultipleUpdates) {
                    // Handle multiple updates
                    Object.entries(key).forEach(([updateKey, updateValue]) => {
                        updatedData[updateKey] = updateValue;
                    });
                } else {
                    // Handle a single update
                    updatedData[key] = value;
                }
        */
    }

    async function handleFileList(files) {

        const deduplicateFiles = (files) => {
            const fileMap = new Map();
            files.forEach(file => {
                fileMap.set(file.id, file);
            });
            return Array.from(fileMap.values());
        };
        // console.log('deduplicateFiles')
        // console.log('files:', files)
        await DataStore.save(Submissions.copyOf(submission, (updatedSubmission) => {
            updatedSubmission.files = deduplicateFiles(files); // Update files list to be the deduplicated list
        }))
            .then((r) => {
                submission = r; // Update local submission variable with the result
            })
            .catch((error) => {
                triggerAlert({
                    message: `Update failed - ${error.message}`, severity: "error",
                });
                console.error(`Failed to update: ${error.message}`);
            });
    }

    function generateField(control, index, handleInputChange, controlStates, submission) {
        const attributes = JSON.parse(control?.attributes || "{}");
        const key = control.key;
        if (controlStates[key]?.isHidden) {
            return
        }
        let label
        try {
            label = control.label ? parse(i18n.get(key)) : null;
        } catch (e) {
            console.log(control, e)
        }

        let elements;

        switch (control.type) {
            case "AFFILIATIONS":
                break;

            case "AUTHORS":
                return <>
                    <div>{parse(i18n.get(control.label || ''))}</div>
                    <Authors data={{
                        authors: controlStates["authors"]?.value || [],
                        affiliations: controlStates["affiliations"]?.value || []
                    }} control={control} currentKey={index} handleInputChange={handleInputChange}/>
                </>;

            case "BUTTON":
                // return <div key={index}>{parse(i18n.get(control.content))}</div>;
                return <ActionButton control={control} submission={submission}/>;

            case "CHECKBOX":
                elements = [];

                control.options.forEach((o, i) => {
                    const option = JSON.parse(o);

                    let valueToCompare = option.value === "true" ? true : option.value === "false" ? false : option.value;

                    switch (typeof valueToCompare) {
                        case "object":
                            valueToCompare = option.value.fee;
                            break;
                        case "string":
                            valueToCompare = isNaN(Number(valueToCompare)) ? valueToCompare : Number(valueToCompare)
                            break;
                        case "boolean":
                            break;
                        default:
                            //number
                            break;
                    }

                    elements.push(
                        <div key={index + "-" + i}>
                            <Checkbox
                                name={key}
                                checked={controlStates[key]?.value === valueToCompare} // Handle both single and multiple selections
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        // Add the selected value
                                        handleInputChange(key, valueToCompare); // Handle single selection case
                                    } else {
                                        // Remove the selected value
                                        handleInputChange(key, undefined); // Handle single selection case (clear value)
                                    }
                                }}
                            />
                            <label>{parse(i18n.get(option.name))}</label>
                        </div>
                    );
                });

                return (
                    <>
                        {label && <FormLabel>{label}</FormLabel>}
                        <FormGroup>{elements}</FormGroup>
                    </>
                );


            case "DATETIME":
                const value = dayjs(controlStates[key]?.value || '2000-01-01')
                return (
                    <>
                        <div>{parse(i18n.get(control.key))}</div>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateCalendar
                                sx={{
                                    transform: 'scale(0.96=5)', // Scale the component to 80% of its original size
                                    transformOrigin: 'top left', // Ensures scaling happens from the top left
                                    marginLeft: 0, // Move the component to the left of its container
                                    position: 'relative', // Positioning context for the transform
                                    marginBottom: 0
                                }}
                                value={value}
                                onChange={(newValue) => handleInputChange(key, dayjs(newValue))}
                                disableFuture={true}
                            />
                        </LocalizationProvider>
                    </>)

            case "FILE": {
                const {key: dataKey, ...otherProps} = control;
                return (
                    <FileUpload
                        dataKey={dataKey}
                        handleFileList={handleFileList}
                        handleInputChange={handleInputChange}
                        {...otherProps}
                    />);
            }

            case "INFORMATION":
                return <div key={index}>{parse(i18n.get(control.content))}</div>;

            case "LANGUAGES":
                const options = form.meta.formLanguages; // Assuming this is an array like ['en', 'fr', 'pt']
                return (<>
                    <div key={index}>{parse(i18n.get(control.content))}</div>
                    <Select
                        sx={{width: 200, height: 30}}
                        autoWidth
                        renderValue={(language) => i18n.get(language)} // Directly translate the selected language code
                        name={key}
                        value={language || 'en'}
                        onChange={(e) => {
                            dispatch({type: 'SET_LANGUAGE', value: e.target.value});
                            console.log(key);  // Update control state here as needed
                        }}
                    >
                        {options.map((option, i) => (<MenuItem key={`${index}-${i}`} value={option}>
                            {i18n.get(option)} {/* Translate each option */}
                        </MenuItem>))}
                    </Select>
                </>);

            case "MESSAGE": {
                return null;
            }

            case "RADIO":
                elements = [];
                control.options.forEach((o, i) => {
                    const option = JSON.parse(o);
                    // Convert the value to a boolean if it's a boolean-like string
                    let value = option.value;
                    if (value === "true") value = true;
                    if (value === "false") value = false;
                    if (value === "null") value = null;
                    if (value === "undefined") value = undefined;

                    elements.push(<div key={index + "-" + i}>
                        <Radio
                            name={key}
                            onChange={(e) => {
                                let newValue = e.target.value;
                                if (newValue === "true") newValue = true;
                                if (newValue === "false") newValue = false;
                                handleInputChange(key, newValue);
                            }}
                            value={option.value}
                            checked={option.value === controlStates[key]?.value}
                        ></Radio>
                        <label>{parse(i18n.get(option.name))}</label>
                    </div>);
                });
                return (<>
                    <FormLabel>{label}</FormLabel>
                    <RadioGroup>{elements}</RadioGroup>
                </>);

            case "RATING": {

                const options = control.options.map(o => JSON.parse(o));
                options.unshift({}); // add empty object in position 0

                return (<>
                        <div key={index}>{parse(i18n.get(control.label))}</div>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Rating
                                name="hover-feedback"
                                value={parseFloat(controlStates[key]?.value) || 0}  // Convert to number, default to 0 if undefined
                                precision={1}
                                max={control.options.length}
                                // getLabelText={getLabelText}
                                onChange={(event, newValue) => {
                                    handleInputChange(key, newValue)
                                }}
                                onChangeActive={(event, newHover) => {
                                    handleHoverChange(key, newHover);
                                }}
                                emptyIcon={<StarIcon style={{opacity: 0.55}} fontSize="inherit"/>}
                            />
                            {(
                                <Box sx={{ml: 2}}>
                                    {i18n.get(options[controlStates[key]?.hover !== -1 ? controlStates[key]?.hover : controlStates[key]?.value]?.name)}
                                </Box>
                            )}

                        </Box>
                    </>
                );
            }
            case "SELECT": {
                // TODO: Turn this into a custom component

                let options;
                if (control.options.length === 1) {
                    options = countryList; // Array[JSON.parse(control.options[0])]
                } else {
                    options = control.options.map((item) => JSON.parse(item));
                }

                let value = controlStates[key]?.value;
                if (attributes.multiple) {
                    value = value ? value.split(";") : [];
                } else {
                    value = value ? value : "";
                }

                elements = [];
                options.forEach((option, i) => {
                    let isSelected;
                    if (attributes.multiple) {
                        isSelected = controlStates[key]?.value
                            ? controlStates[key].value.split(";").includes(
                                typeof option.value === "object" ? String(option.value.fee) : String(option.value)
                            ) // Check if value is an object and use fee, otherwise use value
                            : false;
                    } else {
                        isSelected = controlStates[key]?.value ===
                            (typeof option.value === "object" ? String(option.value.fee) : String(option.value)); // Check if value is an object and use fee, otherwise use value
                    }

                    // Conditionally render MenuItem with a checkbox only if attributes.multiple is true
                    const menuItemContent = attributes.multiple ? (
                        <FormControlLabel
                            control={<Checkbox checked={isSelected}/>}
                            label={i18n.get(option.name)}
                            sx={{width: "100%"}}
                        />
                    ) : (
                        i18n.get(option.name)
                    ); // When not multiple, just render the option name

                    elements.push(
                        <MenuItem key={index + "-" + i}
                                  value={typeof option.value === 'object' ? option.value.fee : option.value}> {/* Use option.value or option.value.fee */}
                            {menuItemContent}
                        </MenuItem>
                    );
                });

                return (
                    <>
                        <FormLabel>{label}</FormLabel>
                        <Select
                            sx={{width: 200, height: 30}}
                            multiple={attributes.multiple}
                            autoWidth
                            renderValue={(selected) => {
                                if (Array.isArray(selected)) {
                                    return selected.map((sel) => {
                                        const selectedOption = options.find((option) =>
                                            typeof option.value === 'object' ? option.value.fee === sel : option.value === sel
                                        );
                                        return selectedOption ? i18n.get(selectedOption.name) : sel;
                                    }).join("; ");
                                } else {
                                    const selectedOption = options.find((option) =>
                                        typeof option.value === 'object' ? option.value.fee === selected : option.value === selected
                                    );
                                    return selectedOption ? i18n.get(selectedOption.name) : "";
                                }
                            }}
                            value={attributes.multiple ? value : value} // Handles both single and multiple
                            name={key}
                            onChange={(e) => {
                                if (attributes.multiple) {
                                    handleInputChange(key, e.target.value.join(";"), false);
                                } else {
                                    handleInputChange(key, e.target.value, false);
                                }
                            }}
                            onBlur={(e) => {
                                if (attributes.multiple) {
                                    handleInputChange(key, e.target.value.join(";"), true);
                                } else {
                                    handleInputChange(key, e.target.value, true);
                                }
                            }}
                        >
                            {elements}
                        </Select>
                    </>
                );
            }

            case "MATHS":
                return <Maths control={control} submission={submission} handleInputChange={handleInputChange}/>;

            case "SLIDER":
                //TODO this is a placeholder for the MATHS control type

                return <Maths control={control} submission={submission} handleInputChange={handleInputChange}/>;

            case "SPONSOR":
                break;

            case "SUBMISSIONLIST":
                return <SubmissionList control={control} currentKey={index}
                                       handleSubmissionChange={handleSubmissionChange}/>;

            case "SUMMARY":
                return <Summary control={control}/>;

            case "SWITCH":
                break;

            case "TABLE":
                break;

            case "TEXT": {
                if (attributes?.height >= 1) {
                    if (attributes?.rtf) {
                        const rteRef = React.createRef(RichTextEditor)
                        return (<>
                            <FormLabel htmlFor={"textarea" + index}>{label}</FormLabel>
                            <Box
                                sx={{
                                    "&& .ProseMirror": {
                                        height: `${attributes.height * 12 || 100}px`,
                                        overflowY: "auto",
                                    },
                                }}
                            >
                                <RichTextEditor
                                    ref={rteRef}
                                    extensions={[StarterKit, Superscript, Subscript, Underline]} // Or any Tiptap extensions you wish!
                                    content={controlStates[key]?.value || ""} // Initial content for the editor
                                    onBlur={() => handleInputChange(key, rteRef.current?.editor?.getHTML())}
                                    // onUpdate={(e) => handleInputChange(key, rteRef.current?.editor?.getHTML(), false)}

                                    renderControls={() => (
                                        <MenuControlsContainer>
                                            {/*<MenuSelectHeading />*/}
                                            <MenuButtonRedo/>
                                            <MenuButtonUndo/>
                                            <MenuDivider/>
                                            <MenuButtonBold/>
                                            <MenuButtonItalic/>
                                            <MenuButtonUnderline/>
                                            <MenuDivider/>
                                            <MenuButtonBulletedList/>
                                            <MenuButtonOrderedList/>
                                            <MenuDivider/>
                                            <MenuButtonSuperscript/>
                                            <MenuButtonSubscript/>
                                        </MenuControlsContainer>
                                    )}
                                /></Box>
                            {attributes?.maxWords > 0 && (<div>
                                {controlStates[key]?.count} {i18n.get('words')} (<em>{attributes.maxWords} {i18n.get('limit')}</em>)
                            </div>)}
                        </>)
                    } else {
                        return (<>
                            <FormLabel htmlFor={"textarea" + index}>{label}</FormLabel>
                            <TextareaAutosize
                                key={index}
                                id={"textarea" + index}
                                color="primary"
                                minRows={attributes.height}
                                name={key}
                                onBlur={(e) => handleInputChange(key, e.target.value)}
                                onChange={(e) => handleInputChange(key, e.target.value, false)}
                                value={controlStates[key]?.value || ""}
                                placeholder={control.options?.placeholder}
                            />
                            {attributes?.maxWords > 0 && (<div>
                                {controlStates[key]?.count} {i18n.get('words')} (<em>{attributes.maxWords} {i18n.get('limit')}</em>)
                            </div>)}
                        </>)
                    }
                } else {
                    return (<TextField
                        key={index}
                        label={label}
                        color="primary"
                        variant="outlined"
                        name={key}
                        onBlur={(e) => handleInputChange(key, e.target.value)}
                        onChange={(e) => handleInputChange(key, e.target.value, false)}
                        value={controlStates[key]?.value || ""}
                        placeholder={control.options?.placeholder}
                    />);
                }
            }

            case "TOGGLE":
                break;
            default:
                return <div key={index}>{control.type}</div>;
        }
    }

    return (<Box
        id="main-content"
        sx={{
            overflowY: "auto", flexGrow: 1, padding: "20px",
        }}
    >
        <div id="back-to-top-anchor"></div>
        <div style={theme.typography.h6}>{parse(i18n.get(page?.title || ''))}</div>
        {controls.map((control, index) => {
            // eslint-disable-next-line no-eval
            if (eval(control?.hidden)) {
                return null;
            }
            const key = control.key;
            let field;
            if (controlStates) {
                field = generateField(control, index, handleInputChange, controlStates, submission)
            }

            return (
                <>
                    {field &&
                        <Box
                            style={{display: controlStates[key]?.isHidden ? 'none' : 'block'}}
                            key={index + "-box"}
                            sx={{width: "100vw - 20px", padding: "5px 10px 5px 10px"}}
                        >
                            {!!controlStates?.[key] ? (<Badge
                                invisible={!controlStates[key]?.isValid && !control.required} // Show badge only if controlStates[key]?.isValid is true or if control is required and not valid
                                badgeContent={!!control.required && !controlStates[key]?.isValid ? (<AsteriskIcon
                                    style={{color: "red"}}/>) : ((!!control.required && controlStates[key]?.isValid) || !!controlStates[key]?.value ? (
                                    <VerifiedIcon style={{color: "green"}}/>) : null)}

                                style={{width: "100%"}}
                                sx={{
                                    ".MuiBadge-badge": {
                                        transform: "translate(5px, 5px)", // Adjusts the badge position 5px right and 5px down
                                    },
                                }}
                                anchorOrigin={{
                                    vertical: "top", horizontal: "right",
                                }}
                            >
                                <Card
                                    sx={{width: "100%"}}
                                    key={index + "-card"}
                                    variant={"outlined"}
                                >
                                    <CardContent
                                        key={index + "-content"}
                                        sx={{position: "relative"}}
                                    >
                                        <FormControl fullWidth key={index}>
                                            {field}
                                        </FormControl>
                                    </CardContent>
                                </Card>
                            </Badge>) : (<FormControl fullWidth key={index}>
                                {field}
                            </FormControl>)}
                        </Box>}
                </>);
        })}
        <ScrollTop
            scrollPosition={scrollPosition}
            onScrollTopClick={() => handleScrollTopClick("smooth")}
        />
    </Box>);
}

const ScrollTop = ({scrollPosition, onScrollTopClick}) => {
    const trigger = scrollPosition > 100;
    return (<Zoom in={trigger}>
        <Box
            onClick={onScrollTopClick}
            role="presentation"
            sx={{position: "fixed", bottom: 100, right: 16}}
        >
            <Fab size="small" aria-label="scroll back to top" color="secondary">
                <KeyboardArrowUpIcon/>
            </Fab>
        </Box>
    </Zoom>);
};
