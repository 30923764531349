import {useEffect, useMemo} from 'react';

export default function Maths(props) {
    const {submission, control, handleInputChange} = props;
    const attributes = JSON.parse(control?.attributes);

    const total = useMemo(() => {
        let computedTotal = 0;
        attributes.fields.forEach(field => {
            const value = submission.data[field];
            if (typeof value === 'number') {
                computedTotal += value;
            }
        });
        return computedTotal;
    }, [submission.data, attributes.fields]);

    useEffect(() => {
        handleInputChange(control.key, total);
    }, [total]);

    return (
        <>
            <strong>Total: {total}</strong>
        </>
    );
}

